import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
  const { isAuth } = useSelector((state) => state.Athentication);
  const navigate = useNavigate();
  return (
    <>
      {/* Footer Section Start */}
      <div className="marginbootom"></div>
      <footer className="section-t-space mt-4">
        <div className="container-fluid-lg">
          {/* <div className="service-section">
            <div className="row g-3">
              <div className="col-12">
                <div className="service-contain">
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/product.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Every Fresh Products</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/delivery.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Free Delivery For Order Over $50</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/discount.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Daily Mega Discounts</h5>
                    </div>
                  </div>
                  <div className="service-box">
                    <div className="service-image">
                      <img
                        src="https://themes.pixelstrap.com/fastkart/assets/svg/market.svg"
                        className="blur-up lazyload"
                        alt
                      />
                    </div>
                    <div className="service-detail">
                      <h5>Best Price On The Market</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="main-footer section-b-space section-t-space ">
            <div className="row g-md-4 g-3" style={{ marginTop: "30px" }}>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="footer-logo">
                  <div className="theme-logo">
                    <Link to="/">
                      <img
                        src="../assets/images/Logo KiranaWorld.png"
                        className="blur-up lazyload"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="footer-logo-contain">
                    <ul className="address">
                      <li>
                        <i data-feather="home" />
                        <Link>
                          {" "}
                          KiranaWorld- Online Grocery, Vegetables & Meat
                          delivery in Jamshedpur
                        </Link>
                      </li>
                      <li>
                        <i data-feather="mail" />
                        <a href="mailto: care@kiranaworld.in">
                          care@kiranaworld.in
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                <div className="footer-title">
                  <h4>My Account</h4>
                </div>
                <div className="footer-contain">
                  <ul>
                    {isAuth ? (
                      <>
                        <li>
                          <Link to="/account">
                            <a className="text-content">
                              Profile &amp; Details
                            </a>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link to="/login">
                            <a className="text-content">
                              Profile &amp; Details
                            </a>
                          </Link>
                        </li>
                      </>
                    )}
                    {isAuth ? (
                      <>
                        <li>
                          <Link to="/account">
                            <a className="text-content">Order History</a>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link to="/login">
                            <a className="text-content">Order History</a>
                          </Link>
                        </li>
                      </>
                    )}
                    {isAuth ? (
                      <>
                        <li>
                          <Link to="/account">
                            <a className="text-content">Address Manage</a>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          <Link to="/login">
                            <a className="text-content">Address Manage</a>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
              <div className="col-xl col-lg-2 col-sm-3">
                <div className="footer-title">
                  <h4>Information</h4>
                </div>
                <div className="footer-contain">
                  <ul>
                    <li>
                      <div
                        onClick={() => {
                          navigate(`/returnefund`);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth", // Smooth scroll effect
                          });
                        }}
                      >
                        <a className="text-content">Returns</a>
                      </div>
                    </li>

                    <li>
                      <div
                        onClick={() => {
                          navigate(`/faq`);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth", // Smooth scroll effect
                          });
                        }}
                      >
                        <a className="text-content">FAQ</a>
                      </div>
                    </li>

                    <li>
                      <div
                        onClick={() => {
                          navigate(`/privacy`);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth", // Smooth scroll effect
                          });
                        }}
                      >
                        <a className="text-content">Privacy Policy</a>
                      </div>
                    </li>
                    <li>
                      <div
                        onClick={() => {
                          navigate(`/terms-conditions`);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth", // Smooth scroll effect
                          });
                        }}
                      >
                        <a className="text-content">Terms & Conditions</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-sm-3">
                <div className="footer-title">
                  <h4>Who Are We?</h4>
                </div>
                <div className="footer-contain">
                  <ul>
                    <li>
                      <div
                        onClick={() => {
                          navigate(`/about`);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth", // Smooth scroll effect
                          });
                        }}
                      >
                        <a className="text-content">About Us</a>
                      </div>
                    </li>

                    {/* <li>
                      <Link to="/" className="text-content">
                        Career
                      </Link>
                    </li> */}
                    <li>
                      <div
                        onClick={() => {
                          navigate(`/contact-us`);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth", // Smooth scroll effect
                          });
                        }}
                      >
                        <a className="text-content">Contact Us</a>
                      </div>
                    </li>
                    {/* <li>
                      <Link to="/">
                        <a className="text-content">Site Map</a>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                <div className="footer-title">
                  <h4>Contact Us</h4>
                </div>
                <div className="footer-contact">
                  <ul>
                    <li>
                      <div className="footer-number">
                        <i data-feather="phone" />
                        <div className="contact-number">
                          <h5>(+91) 88 77 77 9770</h5>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="footer-number">
                        <i data-feather="mail" />
                        <div className="contact-number">
                          <h6 className="text-content">Email Address :</h6>
                          <h5>
                            <a href="mailto: care@kiranaworld.in">
                              care@kiranaworld.in
                            </a>
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li className="social-app">
                      <h5 className="mb-2 text-content">Download App :</h5>
                      <ul>
                        <li className="mb-0">
                          <a
                            href="https://play.google.com/store/apps"
                            target="_blank"
                          >
                            <img
                              src="https://themes.pixelstrap.com/fastkart/assets/images/playstore.svg"
                              className="blur-up lazyload"
                              alt=""
                            />
                          </a>
                        </li>
                        <li className="mb-0">
                          <a
                            href="https://www.apple.com/in/app-store/"
                            target="_blank"
                          >
                            <img
                              src="https://themes.pixelstrap.com/fastkart/assets/images/appstore.svg"
                              className="blur-up lazyload"
                              alt=""
                            />
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="sub-footer section-small-space">
            <div className="reserve">
              <h6 className="text-content">
                ©2024 Powered By Dexterous Technology
              </h6>
            </div>
            <div className="payment">
              <img
                src="../assets/images/payment/1.png"
                className="blur-up lazyload"
                alt
              />
            </div>
            <div className="social-link">
              <h6 className="text-content">Stay connected :</h6>
              <ul>
                <li>
                  <a href="https://www.facebook.com/" target="_blank">
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/" target="_blank">
                    <i className="fa-brands fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/" target="_blank">
                    <i className="fa-brands fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://in.pinterest.com/" target="_blank">
                    <i className="fa-brands fa-pinterest-p" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Section End    */}
    </>
  );
};

export default Footer;
