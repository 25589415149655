import React from "react";

const Privacy = () => {
  return (
    <>
      <section className="container  mt-2">
        <h3 className=" text-center return">Privacy Policy</h3>
        <hr></hr>
        <div className="">
          <h2 className=" ">Privacy Policy for KiranaWorld.in </h2>
          <p className="mt-lg-5">
            At KiranaWorld, accessible from www.KiranaWorld.in, one of our main
            priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by KiranaWorld and how we use it.
          </p>
          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>
          <h2 className="mt-lg-5">Consent</h2>
          <p className="mt-lg-4">
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </p>
          <h2 className="mt-lg-5">Information We Collect</h2>
          <p className="mt-lg-4">
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, and the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide
          </p>
          <p>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </p>
          <h2 className="mt-lg-5">How We Use Your Information</h2>
          <p className="mt-lg-4">
            We use the information we collect in various ways, including to:
          </p>
          <ul>
            <li>
              <b> 1. </b>Provide, operate, and maintain our website,
            </li>
            <br />
            <li>
              <b> 2. </b>Improve, personalize, and expand our website
            </li>
            <br />
            <li>
              <b> 3. </b>Understand and analyze how you use our website
            </li>
            <br />
            <li>
              <b> 4. </b>Develop new products, services, features, and
              functionality
            </li>
            <br />
            <li>
              <b> 5. </b>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </li>
            <br />
            <li>
              <b> 6. </b>Send you emails
            </li>
            <br />
            <li>
              <b> 7. </b>Find and prevent fraud
            </li>
          </ul>
          <h2 className="mt-lg-4">Log Files</h2>
          <p className="mt-lg-4">
          KiranaWorld follows a standard procedure of using log files. These
            files log visitors when they visit websites. All hosting companies
            do this and a part of hosting services' analytics. The information
            collected by log files include internet protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are
            not linked to any information that is personally identifiable. The
            purpose of the information is for analyzing trends, administering
            the site, tracking users' movement on the website, and gathering
            demographic information. Our Privacy Policy was created with the
            help of the Privacy Policy Generator and the Online Privacy Policy
            Generator.
          </p>

          <h2 className="mt-lg-5">Google DoubleClick DART Cookie</h2>
          <p className="mt-lg-4">
            Google is one of a third-party vendor on our site. It also uses
            cookies, known as DART cookies, to serve ads to our site visitors
            based upon their visit to www.website.com and other sites on the
            internet. However, visitors may choose to decline the use of DART
            cookies by visiting the Google ad and content network Privacy Policy
            at the following URL
            <a href="https://policies.google.com/technologies/ads">
              {" "}
              – https://policies.google.com/technologies/ads
            </a>
          </p>
          <h2 className="mt-lg-5">Our Advertising Partners</h2>
          <p className="mt-lg-4">
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of KiranaWorld.
          </p>
          <p className="mt-lg-4">
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on KiranaWorld,
            which are sent directly to users' browser. They automatically
            receive your IP address when this occurs. These technologies are
            used to measure the effectiveness of their advertising campaigns
            and/or to personalize the advertising content that you see on
            websites that you visit.
          </p>
          <p className="mt-lg-4">
            Note that KiranaWorld has no access to or control over these cookies
            that are used by third-party advertisers.
          </p>
          <h2 className="mt-lg-5"> Third Party Privacy Policies</h2>
          <p className="mt-lg-4">
          KiranaWorld's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options.
          </p>
          <p className="mt-lg-4">
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites .
          </p>

          <h2 className="mt-lg-5">
            {" "}
            Privacy Rights (Do Not Sell My Personal Information){" "}
          </h2>
          <p className="mt-lg-4">
            {" "}
            Among other rights, consumers have the right to:
          </p>
          <p className="mt-lg-4">
            Request that a business that collects a consumer's personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
          </p>
          <p className="mt-lg-4">
            {" "}
            Request that a business delete any personal data about the consumer
            that a business has collected.
          </p>
          <p className="mt-lg-4">
            {" "}
            Request that a business that sells a consumer's personal data, not
            sell the consumer's personal data.
          </p>
          <p className="mt-lg-4">
            {" "}
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>

          <h2 className="mt-lg-5"> GDPR Data Protection Rights </h2>
          <p className="mt-lg-4">
            {" "}
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </p>
          <p className="mt-lg-4">
            {" "}
            The right to access – You have the right to request copies of your
            personal data. We may charge you a small fee for this service.{" "}
          </p>
          <p className="mt-lg-4">
            {" "}
            The right to rectification – You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request that we complete the information you believe is
            incomplete.
          </p>
          <p className="mt-lg-4">
            The right to erasure – You have the right to request that we erase
            your personal data, under certain conditions.{" "}
          </p>
          <p className="mt-lg-4">
            {" "}
            The right to restrict processing – You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.{" "}
          </p>
          <p className="mt-lg-4">
            {" "}
            The right to object to processing – You have the right to object to
            our processing of your personal data, under certain conditions.
          </p>
          <p className="mt-lg-4">
            {" "}
            The right to data portability – You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </p>
          <p className="mt-lg-4">
            {" "}
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>

          <h2 className="mt-lg-5">Children's Information </h2>
          <p className="mt-lg-4">
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>
          <p className="mt-lg-4">
          KiranaWorld does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>

          <h2 className="mt-lg-5">Declaration</h2>
          <p className="mt-lg-4">
            The User hereby consents, expresses and agrees that he has read and
            fully understands the Privacy Policy of ((Website)) The User hereby
            agrees to receive sms and email notifications on his/her registered
            mobile number. The user further consents that the terms and contents
            of such Privacy Policy are acceptable to him.
          </p>
        </div>
      </section>
      <div className="mt-lg-5"></div>
    </>
  );
};

export default Privacy;
