import React from "react";

const TermsConditions = () => {
  return (
    <>
      <section className="container  ">
        <h3 className=" text-center return">Terms and Conditions</h3>
        <hr></hr>
        <div className="">
          <h2 className=" ">INTRODUCTION </h2>
          <p className="mt-lg-5" style={{ textAlign: "justify" }}>
            Thank you for visiting this website KiranaWorld. Please read the
            terms and conditions before accessing this website. We operate this
            site for promoting and selling the products and services supplied by
            us. By using this site, you signify your acceptance of these
            conditions in return for which we will provide you with access. From
            time to time we may modify the conditions so please continue to
            review the conditions of use whenever accessing or using this site.
            If at any time you do not wish to accept these conditions then you
            may not use this site.
          </p>

          <h2 className="mt-lg-5">1. REVISIONS</h2>
          <p className="mt-lg-4" style={{ textAlign: "justify" }}>
            We reserve the right to revise these Terms and Conditions from time
            to time as explained further. Please check periodically for changes.
            If you do not agree with any of the revised Terms and Conditions
            please do not order from KiranaWorld.
          </p>

          <h2 className="mt-lg-5">2. REGISTRATION</h2>

          <ul className="mt-lg-4">
            <li style={{ textAlign: "justify" }}>
              <b> 1. </b>To order from KiranaWorld, you must register with us.
              This is referred to as ‘set up an account’ throughout our website
              and literature. However, this is not a credit account; payment
              will be required upon delivery of goods. This account can be set
              up by following the instructions on the website.
            </li>
            <br />
            <li>
              <b> 2. </b>To register with KiranaWorld you must be over eighteen
              years of age or, if you are a business, a person must operate you
              over eighteen years of age.
            </li>
            <br />
            <li>
              <b> 3. </b>You must ensure that the details provided by you on
              registration or at any time are correct and complete.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 4. </b> You must inform us promptly of any changes to the
              information that you provided when registering by updating your
              personal details, within the order deadlines specified on our
              website.
            </li>
            <br />
            <li>
              <b> 5. </b> The personal details you give to us are governed by a
              strict privacy policy
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 6. </b>Following your set up of an account, we will email
              confirmation of your account set up to you. For your records,
              please print or save this email. For your records, please also
              print or save these terms and conditions for your future
              reference.
            </li>
            <br />
          </ul>
          <h2 className="mt-lg-4">PASSWORD AND SECURITY</h2>
          <ul className="mt-lg-4">
            <li style={{ textAlign: "justify" }}>
              <b> 1. </b>When you register to use the website you will be asked
              to provide OTP. You must keep this password confidential and must
              not disclose it or share it with anyone. You will be responsible
              for all activities and orders that occur or are submitted under
              your password. If you know or suspect that someone else knows your
              password you should notify us by contacting us immediately. We
              would also suggest that you also change your password immediately
              through my account section of the website.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 2. </b>If KiranaWorld team has reason to believe that there is
              likely to be a breach of security or misuse of the KiranaWorld
              website, we may require you to change your password or we may
              suspend or cancel your account.
            </li>
            <br />
          </ul>
          <h2 className="mt-lg-4">PAYMENT</h2>
          <ul className="mt-lg-4">
            <li style={{ textAlign: "justify" }}>
              <b> 1. </b>You must ensure that KiranaWorld are provided with
              accurate personal information, contact nos. and delivery address.
              You must update us with any change to these details within the
              deadlines specified on the website. As of now, KiranaWorld accepts
              online & offline modes of payment.
            </li>
            <br />
          </ul>

          <h2 className="mt-lg-5">PRODUCT PURCHASES</h2>

          <ul className="mt-lg-4">
            <li style={{ textAlign: "justify" }}>
              <b> 1. </b>You must be over the age of eighteen to purchase
              products from KiranaWorld. If you have ordered any product from us
              and cannot be at home at your selected delivery time, it is your
              responsibility to ensure that a suitable person aged eighteen or
              older is there to receive the order.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 2. </b>You may submit orders for products by using our online
              ordering facility on the website/app at any time after you have
              created an account. The facility and our communications to you in
              relation to any order or contract will be in the English language.
              The facility allows you to review your order and make any
              corrections before submitting it to us and by submitting the order
              you confirm that you have made any such corrections.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 3. </b>Each product for which you click ‘Add’ is added to ‘My
              cart’ facility. You are given the opportunity to review the
              quantity and frequency of order in your Dashboard when you choose
              to ‘check out’. You can change these, or cancel the purchase of
              each of the products completely, at this stage, at any time before
              clicking ‘place order’. After you have clicked ‘place order’ and
              after our acceptance of your order, items are added to your
              delivery in addition to any items that you have previously
              ordered. You can view your order details by clicking on ‘my
              Account’ whenever you are logged in.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 4. </b> Your submission of an order amounts to an offer to
              enter a contract to buy the products from us; subject to point
              5.3, you cannot then withdraw or cancel your order except as
              stated below.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 5. </b> Order is accepted from you until our website displays
              an order confirmation message. This message is displayed after you
              click ‘place order’ located at bottom of the page. When you order
              by telephone, your order will be accepted (or rejected, as the
              case may be) verbally by our staff. A contract for our sale of
              products to you arises on our acceptance of your order.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 6. </b>Where we accept an order, we do all that we can to
              ensure that your order is fulfilled. Products are, however,
              subject to availability and market conditions and we do not always
              know if a product is or will be available at the time of accepting
              an order. If we are unable to deliver an item you have ordered, we
              may offer a reasonable substitute. You may reject that substitute,
              and you will not be charged for it or if you have paid already, we
              will refund any amount paid for it. If we do not offer a
              substitute, we will remove the product from your order so that you
              are not charged or if you have paid already, we will refund you
              the price. We will, however, bear no liability for unavailability
              of products.
            </li>
            <br />
            <li>
              <b> 7. </b>Subject to clauses 5.8 and 5.9. Below, the price that
              we charge you for the products will be the price stated by us on
              the website prior to you clicking the ‘place order’ button.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 8. </b>All products offered by us are subject to seasonal
              changes in supply levels and market prices. If you are a
              ‘subscription’ customer, (e.g. where you choose to have repeat
              orders of products) the prices for certain products may therefore
              differ from the price for those products when first ordered by
              you. The prices for all products ordered on a subscription basis
              shall be those prices for the products stated on our website. We
              recommend that you check the website regularly. The website still
              allows you to review all the products (and their prices) that you
              have already ordered from us, and make amendments and corrections
              to them until the deadlines published on the website.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 9. </b>Some of our products are priced by weight (for example,
              watermelon, and papaya). We show the prices for weights on the
              website. The guide weight is for guidance only; the weight you
              receive may vary from this guide and the price you are charged
              will be for the weight of product delivered.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 10. </b>Ordering a product from us carries with it the
              obligation to pay for it unless we receive from you a cancellation
              of or change to your orders before the deadlines published on the
              website. We are also happy to inform you of these deadlines by
              telephone if you wish to call us. You remain responsible for
              ensuring that any such change or cancellation is not only
              transmitted by you, but received by us, in time for the deadline.
              We reserve the right to deliver and charge in full for any order
              unless we have received notice of cancellation before the
              published deadline. This deadline is important since, typically,
              we make up the deliveries for dispatch to you the day before
              delivery and obtain some of the products especially to fulfill
              your order and may not be able to sell the products elsewhere. Any
              payments you have made for orders, which have been properly
              cancelled, will be return to you. Despite the foregoing, if you
              are a consumer, then you may also cancel the order in accordance
              with paragraph 9.3 below in so far as the order is for
              non-perishable products.
            </li>
            <br />
            <li>
              <b> 11. </b>We sell only to end users and our products are not for
              re-sale. You warrant that you shall not re-sell any of the
              products.
            </li>
            <br />
            <li>
              <b> 12. </b>All prices for products are inclusive of any
              applicable taxes.
            </li>
            <br />
          </ul>
          <h2 className="mt-lg-5">
            {" "}
            DELIVERY OF PRODUCTS AND INSPECTION BY YOU
          </h2>

          <ul className="mt-lg-4">
            <li style={{ textAlign: "justify" }}>
              <b> 1. </b>We will make delivery to your address as stated when
              you set up an account or update that address. We reserve the right
              not to deliver to all locations and we will tell you when you
              apply to register an account with us whether we can deliver to
              your address. You are responsible for making suitable arrangements
              to receive your delivery and giving us appropriate instructions.
              In the event that your delivery is stolen from your doorstep or
              from security guards or damaged while there, we do not accept
              liability and will offer compensation at our discretion. We
              reserve the right to refuse to accept orders from any customer;
              considerations of delivery problems may give rise to such a
              refusal. Any changes to address details must be made before the
              order deadlines published on our website.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 2. </b>We endeavor to keep your delivery time the same you
              mentioned while placing your order, but we reserve the right to
              change it temporarily or permanently and will tell you if we do
              so.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 3. </b>If we are unable to deliver to you, or have to deliver
              late, for reasons beyond our control, for example adverse weather
              conditions, strike / ‘band’ actions, vehicle breakdown, traffic
              congestion or supplier failure, we cannot accept liability for any
              inconvenience or loss that this causes. We will not, of course,
              charge for products unless or until we have delivered them to you.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 4. </b> We will not charge you for incorrect products
              (products that you have not ordered and substitutes for those
              products, which substitutes, are not acceptable to you) or
              products, which we have not delivered in accordance with these
              Terms and Conditions. Otherwise, our liability in respect of
              incorrect delivery is limited to the price of the incorrect
              products or the products not so delivered subject as provided in
              points 10 below.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 5. </b> You must inspect the products as soon as possible
              after delivery and notify us promptly if you find any defects, by
              calling us, emailing us or writing to us at the telephone number
              or address shown on our website. We will replace or refund you for
              defective products. Otherwise, our liability is limited as
              provided in points 10.
            </li>
            <br />
          </ul>
          <h2 className="mt-lg-5"> EXCLUDED SERVICES</h2>

          <ul className="mt-lg-4">
            <li>
              <b> 1. </b>It is your responsibility, at your cost, to obtain
              computer, internet connectivity, telecommunications or other
              necessary equipment or services to access the website.
            </li>
            <br />
          </ul>
          <h2 className="mt-lg-5"> VAILABILITY OF THE WEBSITE / SERVICE</h2>

          <ul className="mt-lg-4">
            <li style={{ textAlign: "justify" }}>
              <b> 1. </b>Although KiranaWorld aims to offer you the best service
              possible, we make no promise that our website services will meet
              your requirements and we cannot guarantee that the service will be
              fault free. If a fault occurs in the service, please report it to
              us (see below for contact details) and we will correct the fault
              as soon as we reasonably can.
            </li>
            <li style={{ textAlign: "justify" }}>
              <b> 2. </b>Your access to the website may be occasionally
              restricted to allow for repairs, maintenance or the introduction
              of new facilities or services. We will restore the service as soon
              as we reasonably can. In the event that our website is
              unavailable, our usual order and cancellation deadlines apply;
            </li>
            <br />
          </ul>
          <h2 className="mt-lg-5"> CANCELLATION RIGHTS</h2>

          <ul className="mt-lg-4">
            <li style={{ textAlign: "justify" }}>
              <b> 1. </b>KiranaWorld may suspend or cancel any accepted order or
              your registration immediately at our reasonable discretion or if
              you breach any of your obligations under these Terms and
              Conditions
              <br />
            </li>
            <li style={{ textAlign: "justify" }}>
              <b> 2. </b>You can cancel your registration with us at any time by
              informing us in writing. If you do so, you must stop using
              KiranaWorld services.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 3. </b>The suspension or cancellation of your registration
              shall not affect your and/or our rights or liabilities accrued to
              the date of suspension or cancellation or any orders submitted by
              you before we received notice of your cancellation, and you will
              continue to be bound by such orders
            </li>
            <br />
          </ul>
          <h2 className="mt-lg-5"> WARRANTIES AND KiranaWorld LIABILITIES</h2>

          <ul className="mt-lg-4">
            <li style={{ textAlign: "justify" }}>
              <b> 1. </b>Whilst KiranaWorld tries to ensure that material
              included on the website or in any other catalogue or materials is
              correct, we cannot accept responsibility if, despite our
              endeavors, this is not the case. Without prejudice to your
              statutory rights (see points 11, below), KiranaWorld may correct
              any inaccuracies and/or errors and we will not be responsible for
              any such inaccuracies and/or errors or for the results obtained
              from the use of such information or for any technical problems you
              may experience with the website.
              <br />
            </li>
            <li style={{ textAlign: "justify" }}>
              <b> 2. </b>We are not manufacturer of any products. Products sold
              under the brand name of “Kirana World” is not our own manufactured
              product, We pick the best quality product from the market and pack
              it without any modification in quality etc. We do packaging
              activity only to ensure best quality and safe delivery of
              products.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 3. </b>We warrant that the products will be of satisfactory
              quality and, therefore, if the products, which you purchase from
              us, are faulty or defective you should notify us in accordance
              with points 6.6. We will examine the products and, if the products
              are faulty or defective, we will replace the faulty or defective
              products or refund the price of the faulty or defective products.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 4. </b>The warranty in points 10.2 above does not apply to
              faults or defects which been caused by your miss-use and/or
              neglect of the products or by accidents caused while the products
              are in your possession.
              <br />
            </li>
            <li style={{ textAlign: "justify" }}>
              <b> 5. </b>Subject to the provisions of points 6.3 And 10.6, our
              entire liability in connection with the contract for the supply
              and purchase of products and will not exceed the purchase price of
              the products in question.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 6. </b>Nothing in these Terms and Conditions means that our
              liability to you for death or personal injury resulting from our
              negligence or that of our employees, agents or subcontractors is
              limited.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 7. </b>We shall not be liable for any delay or failure in the
              performance of our obligations due to events beyond our reasonable
              control such as but not limited to fire, flood, adverse weather,
              acts of God, strikes, labour disputes / band, riots, civil unrest,
              accident, disruption to energy supplies, equipment or supplier
              failure, road traffic problems, terrorism or war.
              <br />
            </li>
            <br />
          </ul>
          <h2 className="mt-lg-5"> APPLICABLE LAW</h2>

          <ul className="mt-lg-4">
            <li>
              <b> 1. </b>These Terms and Conditions shall be governed by and
              construed in accordance with the Indian laws and according to
              permissions from local administration.
              <br />
            </li>
            <li>
              <b> 2. </b>The legal courts will have non-exclusive jurisdiction
              over any disputes arising under or in relation to these Terms and
              Conditions.
            </li>
            <br />
          </ul>
          <h2 className="mt-lg-5"> MISCELLANEOUS</h2>

          <ul className="mt-lg-4">
            <li>
              <b> 1. </b>You may not assign, sub-license or otherwise transfer
              any of your rights under these Terms and Conditions.
              <br />
            </li>
            <li style={{ textAlign: "justify" }}>
              <b> 2. </b>If any provision of these Terms and Conditions is found
              to be invalid by any court having competent jurisdiction, the
              invalidity of that provision will not affect the validity of the
              remaining provisions of these Terms and Conditions, which shall
              remain in full force and effect.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 3. </b>The provisions of the Contracts are expressly excluded
              from our contractual relationship with you so that no third party
              may claim any rights under that contractual relationship (but this
              shall not affect any right or remedy of a third party, which
              exists or is available apart from that Act).
            </li>
            <br />
          </ul>
          <h2 className="mt-lg-5"> AMENDMENTS</h2>

          <ul className="mt-lg-4">
            <li style={{ textAlign: "justify" }}>
              <b> 1. </b>We may update these Terms and Conditions from time to
              time by posting the updated Terms and conditions on our website in
              place of these. The changes will apply to the use of the
              KiranaWorld website after we have given notice. If you do not wish
              to accept the new Terms and Conditions, you should not continue to
              order from KiranaWorld or use the KiranaWorld website. If you
              continue to use the KiranaWorld website after the date on which
              the change comes into effect, your use of the KiranaWorld website
              indicates your agreement to be bound by the new Terms and
              Conditions.
              <br />
            </li>
            <li>
              <b> 2. </b>The legal courts will have non-exclusive jurisdiction
              over any disputes arising under or in relation to these Terms and
              Conditions.
            </li>
            <br />
          </ul>
          <h2 className="mt-lg-5"> MISCELLANEOUS</h2>

          <ul className="mt-lg-4">
            <li>
              <b> 1. </b>You may not assign, sub-license or otherwise transfer
              any of your rights under these Terms and Conditions.
              <br />
            </li>
            <li style={{ textAlign: "justify" }}>
              <b> 2. </b>If any provision of these Terms and Conditions is found
              to be invalid by any court having competent jurisdiction, the
              invalidity of that provision will not affect the validity of the
              remaining provisions of these Terms and Conditions, which shall
              remain in full force and effect.
            </li>
            <br />
            <li style={{ textAlign: "justify" }}>
              <b> 3. </b>The provisions of the Contracts are expressly excluded
              from our contractual relationship with you so that no third party
              may claim any rights under that contractual relationship (but this
              shall not affect any right or remedy of a third party, which
              exists or is available apart from that Act).
            </li>
            <br />
          </ul>
          <h2 className="mt-lg-5"> AMENDMENTS</h2>

          <ul className="mt-lg-4">
            <li style={{ textAlign: "justify" }}>
              <b> 1. </b>We may update these Terms and Conditions from time to
              time by posting the updated Terms and conditions on our website in
              place of these. The changes will apply to the use of the
              KiranaWorld website after we have given notice. If you do not wish
              to accept the new Terms and Conditions, you should not continue to
              order from KiranaWorld or use the KiranaWorld website. If you
              continue to use the KiranaWorld website after the date on which
              the change comes into effect, your use of the KiranaWorld website
              indicates your agreement to be bound by the new Terms and
              Conditions.
              <br />
            </li>
          </ul>
          <h2 className="mt-lg-5"> Declaration</h2>

          <ul className="mt-lg-4">
            <li style={{ textAlign: "justify" }}>
              <b> 1. </b>The User hereby consents, expresses and agrees that he
              has read and fully understands the Privacy Policy of ((Website))
              The User hereby agrees to receive sms and email notifications on
              his/her registered mobile number. The user further consents that
              the terms and contents of such Privacy Policy are acceptable to
              him.
              <br />
            </li>
          </ul>
        </div>
      </section>
      <div className="mt-lg-5"></div>
    </>
  );
};

export default TermsConditions;
